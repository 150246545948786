body {
  font-family: 'M PLUS Rounded 1c', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
}

.cookies-bar {
  border-top: 3px solid #6d5ae0;
  background-color: #000;
  opacity: 0.9;
  max-height: 360px;
}

.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: gainsboro;
  font-weight: normal;
  opacity: 0.95;
  /* Firefox */
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: gainsboro;
  font-weight: normal;
  opacity: 0.95;
  /* Firefox */
}

.form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: gainsboro;
  font-weight: normal;
  opacity: 0.95;
  /* Firefox */
}

.form-control.readonly {
  background-color: whitesmoke;
  box-shadow: none;
}

.form-control.readonly:focus {
  box-shadow: none;
  border: 1px solid lightgray;
}

.overlay {
  /* Height & width depends on how you want to reveal the overlay (see JS below) */
  height: 100%;
  width: 100%;
  position: fixed;
  /* Stay in place */
  z-index: 1301;
  /* Sit on top */
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 30px;
  left: 0;
  top: 0;
  background-color: rgb(255, 255, 255);
  /* Black fallback color */
  background-color: rgba(255, 255, 255, 0.6);
  /* Black w/opacity */
  /* overflow-x: hidden; */
  /* Disable horizontal scroll */
  text-align: center;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.popup {
  /* height: 10%; */
  z-index: 1302;
  padding-left: 1.5vw;
  padding-right: 1.5vw;
  width: 100%;
  max-width: 500px;
  display: inline-block;
}

.hidden {
  visibility: hidden;
}

.fadeIn {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.25s;
}

.fadeOut {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.25s, opacity 0.25s linear;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border: 1px solid #555;
}

/* Track */

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: var(--color-main);
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

h4,
h5 {
  color: #000000de;
  font-weight: 700;
}

h4 {
  font-size: 1.3rem;
}

h5 {
  font-size: 1.2rem;
}

h6 {
  font-size: 0.95rem;
}

/* Textos em geral */

p,
li,
label,
td,
th,
tr,
textarea {
  color: #707070;
  font-size: 12px;
  font-size: 0.85rem;
}

.table td {
  padding: 5px;
  vertical-align: middle;
}

a,
u {
  text-decoration: none !important;
}

.btn,
.row,
.card,
.form-control,
.input-group-text {
  font-size: 12px;
  font-size: 0.85rem;
}

.form-control {
  border: 1px solid lightgray;
  box-shadow: none;
}

.form-control-label-red {
  background-color: #ff6666;
  color: #fff;
  font-weight: bold;
}

.form-control:focus {
  box-shadow: none;
  border: 2px solid var(--color-main);
}

.font10 {
  font-size: 10px;
  font-size: 0.75rem;
}

.font12 {
  font-size: 12px;
  font-size: 0.7rem;
}

.font14 {
  font-size: 14px;
  font-size: 0.8rem;
}

.font16 {
  font-size: 16px;
  font-size: 0.85rem;
}

.font18 {
  font-size: 18px;
  font-size: 0.92rem;
}

.font20 {
  font-size: 20px;
  font-size: 1.2rem;
}

.font24 {
  font-size: 24px;
  font-size: 1.25rem;
}

.bold {
  font-weight: 700;
}

.circle {
  height: 60px;
  width: 60px;
  border: 6px solid lightgray;
  border-radius: 50%;
  display: inline-block;
}

.verticalAlign {
  vertical-align: 'middle';
}

.BgGradientPadrao {
  background-image: linear-gradient(#c302fc, #01e1d6);
}

.BgGradientPadraoInverso {
  background-image: linear-gradient(#01e1d6, #c302fc);
}

.text-main {
  color: var(--color-main);
}

.text-white {
  color: white;
}

.text-lightgrey {
  color: grey;
}

.text-darkgrey {
  color: rgb(77, 77, 77);
}

.text-semiwhite {
  color: lightgray;
}

.text-green {
  color: #00645f;
}

.text-ciano {
  color: #00c9bd;
}

.text-scrollable {
  max-height: 80%;
  max-height: 60vh;
  overflow-y: auto;
}

.text-scrollable::-webkit-scrollbar {
  width: 5px;
}

.bg-lightgray {
  background-color: lightgray;
}

.btn-xm {
  font-size: 12px;
  font-size: 0.7rem;
  font-weight: 700;
  line-height: 1em;
  padding: 0.25em 0.4em;
  border-radius: 0.25rem;
}

.btn-label {
  position: relative;
  left: -12px;
  display: inline-block;
  padding: 6px 12px;
  background: rgba(0, 0, 0, 0.15);
  border-radius: 3px 0 0 3px;
}

.btn-labeled {
  padding-top: 0;
  padding-bottom: 0;
}

.btn:focus {
  box-shadow: none;
}

.btn-ciano {
  color: white;
  background-color: #00c9bd;
}

.btn-ciano:hover {
  color: white;
  background-color: #00aca0;
}

.btn-white {
  background-color: white;
  color: gray;
}

.btn-white:hover {
  color: black;
}

.btn-black {
  background-color: black;
  color: lightgray;
}

.btn-black:hover {
  background-color: #080808;
  color: white;
}

.btn-lightgray {
  background-color: #e9ecef;
  color: gray;
}

.btn-lightgray:hover {
  background-color: #d1d1d1;
  color: gray;
}

.btn-gray {
  background-color: rgb(138, 138, 138);
  color: rgb(255, 255, 255);
}

.btn-gray:hover {
  background-color: #4b4b4b;
  color: white;
}

.btn-main {
  background-color: var(--color-main);
  color: white;
  font-weight: bold;
}

.btn-main:hover {
  background-color: var(--color-main-light);
  color: white;
}

.badge-ciano {
  color: white;
  background-color: #00c9bd;
}

.icoPatol {
  width: 35px;
  height: 35px;
  margin: 20px;
}

.cardPadrao {
  padding: 5px;
  border-radius: 15px;
  box-shadow: 0 0.5rem 1rem rgba(55, 55, 55, 0.15);
}

.cardPadraoShadowBlack {
  padding: 5px;
  border-radius: 15px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.7);
}

.menuPopup {
  padding: 5px;
  border-radius: 10px;
  border: 1px darkgray solid;
  box-shadow: 0 0.3rem 0.3rem rgba(0, 0, 0, 0.5);
}

.cardCookieShadowBlack {
  padding: 5px 20px;
  border-radius: 15px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.7);
}

.cardPadraoNoShadow {
  padding: 5px;
  border-radius: 15px;
}

.cardPatol {
  border-radius: 15px;
  box-shadow: 0px 4px 2px rgba(51, 51, 51, 0.04),
    0px 4px 16px rgba(51, 51, 51, 0.08);
  /* box-shadow: 0 0.5rem 1rem rgba(55, 55, 55, 0.15); */
}

.cardPatolSelected {
  border-radius: 15px;
  border: 1px solid #00978d;
  box-shadow: 0px 4px 2px rgba(51, 51, 51, 0.04),
    0px 4px 16px rgba(51, 51, 51, 0.08);

  /* box-shadow: 0px 2px 2px rgba(51, 51, 51, 0.04), 0px 4px 16px rgba(51, 51, 51, 0.58); */
}

.shadowPatolAlta {
  box-shadow: 0 0.5rem 1rem rgba(195, 2, 252, 0.55);
}

.bgPatologiaBar {
  border-radius: 15px;
  height: 15px;
  line-height: 1em;
}

.bgPatologiaBar.decrescente {
  background-image: linear-gradient(to right, #c302fc, #01e1d6);
}

.bgPatologiaBar.crescente {
  background-image: linear-gradient(to right, #01e1d6, #c302fc);
}

.bgPatologiaBar.neutra {
  background-color: #666eea;
}

.patologiaCardBar {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0px 8px 8px 8px;
}

.testimonial-group > .row {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}

.testimonial-group > .row > .col-xs-4 {
  flex: 0 0 auto;
}

/* Hide scrollbar for IE, Edge and Firefox */

.testimonial-group {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* Logo pulse no Loader.js */

.logoBounce {
  animation: logoBounce 2s ease infinite;
  width: 130px;
}

.logoBounce-small {
  animation: logoBounce 2s ease infinite;
  width: 60px;
}

@keyframes logoBounce {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
}

/* .tip-heart {
  width: 100px;
  height: 100px;
  position: absolute;
  left: 50%;
  top: 30%;
  transform: translate(-50%, -50%);
  background: url(/images/heart.png) no-repeat;
  background-position: 0 0;
  cursor: pointer;
}

.tip-heart-like {
  background: url(/images/heart.png) no-repeat;
  background-position: -2800px 0;
  transition: background 1s steps(28);
} */

@keyframes fave-heart {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -2800px 0;
  }
}

.table-overlay {
  content: '';
  /* pointer-events: none;   */
  position: relative;
}

.table-overlay.loading:before {
  content: '';
  display: block;
  width: 110%;
  height: 100%;
  position: absolute;
  top: 30px;
  left: -10px;
  bottom: 0;
  right: 0;
  z-index: 10;
  background-color: rgb(255, 255, 255);
  background-color: rgba(255, 255, 255, 0.8);
}

.no-select-text {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.diagCardPalavra {
  line-height: 1.3em;
}

.nav-pills > li > a.active {
  font-weight: bold;
  background-color: var(--color-main) !important;
}

.dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  box-shadow: 9999px 0 0 -5px #9880ff;
  animation: dotPulse 1.5s infinite linear;
  animation-delay: 0.25s;
}

.dot-pulse::before,
.dot-pulse::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
}

.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px #9880ff;
  animation: dotPulseBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px #9880ff;
  animation: dotPulseAfter 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9984px 0 0 -5px #9880ff;
  }
  30% {
    box-shadow: 9984px 0 0 2px #9880ff;
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px #9880ff;
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -5px #9880ff;
  }
  30% {
    box-shadow: 9999px 0 0 2px #9880ff;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px #9880ff;
  }
}

@keyframes dotPulseAfter {
  0% {
    box-shadow: 10014px 0 0 -5px #9880ff;
  }
  30% {
    box-shadow: 10014px 0 0 2px #9880ff;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px #9880ff;
  }
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.hs-title {
  color: #009bc1;
  font-weight: bold;
  font-size: 1.2rem;
}

[data-toggle='collapse'] .fa-arrow:after {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  float: left;
  margin-right: 10px;
  content: '\f106';
}

[data-toggle='collapse'].collapsed .fa-arrow:after {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  float: left;
  margin-right: 10px;
  content: '\f107';
}

.button_loading {
  width: 26px;
  height: 26px;
  border: 4px solid #9880ff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.button_loading_sm {
  width: 19px;
  height: 19px;
  border: 2px solid #9880ff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.button_loading_white_sm {
  width: 19px;
  height: 19px;
  border: 2px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.panel-title a {
  display: block;
  color: #000;
  font-weight: bold;
  text-decoration: none;
}
.panel-title a:after {
  font-family: Fontawesome;
  content: '\f077';
  float: left;
  margin-right: 5px;
  font-size: 10px;
}

.panel-title a.collapsed:after {
  font-family: Fontawesome;
  content: '\f078';
}

.slider {
  left: 0;
  position: absolute;
  width: 100%;
  height: 5px;
  overflow-x: hidden;
}

.line {
  position: absolute;
  opacity: 0.4;
  background: #0f972e;
  width: 100%;
  height: 5px;
}

.line-red {
  position: absolute;
  opacity: 0.4;
  background: #c80e0e;
  width: 100%;
  height: 5px;
}

.subline {
  position: absolute;
  background: #0f972e;
  height: 5px;
}

.subline-red {
  position: absolute;
  background: #c80e0e;
  height: 5px;
}

.inc {
  animation: increase 2s infinite;
}

.dec {
  animation: decrease 2s 1s infinite;
}

@keyframes increase {
  from {
    left: -5%;
    width: 5%;
  }
  to {
    left: 130%;
    width: 100%;
  }
}

@keyframes decrease {
  from {
    left: -80%;
    width: 80%;
  }
  to {
    left: 110%;
    width: 10%;
  }
}

.overlay-internal {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 100;
  padding-left: 1rem;
  padding-right: 1rem;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.6);
  text-align: center;
}

.tp-input {
  width: 50px;
  font-size: 13px !important;
  font-weight: bold;
  color: #000;
  border-radius: 0.25rem;
}

.border-hspw {
  border: 2px solid #4e00ad;
  border-radius: 0.3rem;
}
