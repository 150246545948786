.loadingLogo {
	width: 100%;
	height: auto;
	bottom: 0px;
	top: 0px;
	left: 0;
	position: absolute;
	z-index: 200000001;
}

.loadingCenter {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -65px;
	margin-left: -51px;
	z-index: 200000001;
	text-align: center;
}

.loadingBounce {
	text-align: center;
	animation: logoBounce 2s ease infinite;
	z-index: 200000001;
}

.loadingText {
	line-height: 2em;
}

@keyframes logoBounce {
	0%, 100% {
		opacity: 1;
	}
	50% {
		opacity: .1;
	}
}