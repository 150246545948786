.likert {
    list-style: none; 
    width: 100%;
    margin: 0;
    padding: 0 0 35px;
    display: block;

}

.likert:last-of-type {
    border-bottom: 0;
}

.likert:before {
    content: "";
    position: relative;
    top: 10px;
    left: 0px;
    display: block;
    background-color: #efefef;
    height: 4px;
    width: 100%;
}

.likert li {
    display: inline-block;
    /* width: 20%; 
    text-align: center; */
    vertical-align: top;
    margin: 0px 6px 0px 6px;
}

.likert li button {
    border-radius: 50%;
    height: 18px;
    width: 18px;
    border: solid 2px grey;
    display: block;
    position: relative;
    top: 0;
    left: 50%;
    margin-left: -6px;
    cursor: pointer;
}

.likert li button:active, .likert li button:focus {
    box-shadow: none;
    background-color: #00C9BD;
    color: #00C9BD;
    outline:none;
    border: none;
}

.likert li label {
    width: 100%;
    font-weight: bold;
    font-size: 10px;
    font-size: 0.65rem;
}